(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("createFolderController", createFolderController);

	function createFolderController($scope, $mdDialog, $filter, $http, $translate, coreDataMail, userDataService, parentFolder, owner, folders) {
		$scope.controller = this;
		$scope.title = $translate.instant("NEW_FOLDER");
		$scope.name = "";
		$scope.parentFolder = parentFolder; //parentFolder == "Inbox" ? "" : parentFolder;
		$scope.selectedFolder = parentFolder; //parentFolder == "Inbox" ? "" : parentFolder;
		$scope.owner = owner;

		if (parentFolder.access === 3) {
			$scope.folders = orderFolders($.grep(folders, function (f) { return f.access === 3; }));
		} else {
			$scope.folders = orderFolders($.grep(folders, function (f) { return f.ownerEmailAddress === userDataService.user.emailAddress; }));
			$scope.folders.unshift({ name: "", path: "", translatedName: ("- " + $filter("translate")("ROOT_FOLDER") + " -") });
		}

		function orderFolders(folders) {
			const orderedFolders = [];
			const processedFolderIds = new Set();

			function processFolder(folder, level) {
				if (processedFolderIds.has(folder.id)) {
					return;
				}
				processedFolderIds.add(folder.id);
				orderedFolders.push(folder);
				
				if (folder.subFolders && folder.subFolders.length > 0) {
					folder.subFolders.sort((a, b) => a.name.localeCompare(b.name));
					folder.subFolders.forEach(subFolder => {
						processFolder(subFolder, level + 1);
					});
				}
			}

			folders.forEach(folder => {
				if (!processedFolderIds.has(folder.id)) {
					processFolder(folder, 0);
				}
			});

			return orderedFolders;
		}


		$scope.done = function () {
			if (!$scope.name || $scope.name.length === 0)
				return;

			// Remove trailing .'s
			while ($scope.name.charAt($scope.name.length - 1) === ".") {
				$scope.name = $scope.name.substring(0, $scope.name.length - 1);
			}

			var parameters = {};

			if ($scope.selectedFolder && $scope.selectedFolder.access === 3) {
				parameters = {
					"folder": $scope.name,
					"parentFolder": $scope.selectedFolder.path,
					"ownerEmailAddress": $scope.owner
				};
			} else {
				parameters = {
					"folder": $scope.name,
					"parentFolder": $scope.selectedFolder.path
				};
			}
			coreDataMail.ignoreFolderUpdate.requested = Date.now();
			$http
				.post("~/api/v1/folders/folder-put", parameters)
				.then(function (response) {
					$mdDialog.hide({ "success": response.data.success, "folder": response.data.folder, "name": $scope.name, "response": response });
				}, function (response) {
					$mdDialog.hide({ "success": false, "response": response });
				});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		var hasFolder = false;
		for (var i = 0; i < $scope.folders.length; ++i) {
			if ($scope.folders[i].path.toLowerCase() === $scope.selectedFolder.path.toLowerCase()) {
				$scope.selectedFolder = $scope.folders[i];
				hasFolder = true;
				break;
			}
		}
		if (!hasFolder) {
			$scope.selectedFolder = $scope.folders[0];
		}
	}
})();
